@charset "utf-8";

// Import a Google Font
@import url('https://fonts.googleapis.com/css?family=Open+Sans:400,700');

// Update Bulma's global variables
$family-sans-serif: "Open Sans", sans-serif;
// $grey-dark: $brown;
// $grey-light: $beige-light;
$primary: #c3192e;
$widescreen-enabled: false;
$fullhd-enabled: false;

// Update some of Bulma's component variables
// $body-background-color: $beige-lighter;

// Import only what you need from Bulma
@import "bulma/sass/utilities/_all.sass";
@import "bulma/sass/base/_all.sass";
@import "bulma/sass/elements/button.sass";
@import "bulma/sass/elements/container.sass";
@import "bulma/sass/elements/title.sass";
@import "bulma/sass/form/_all.sass";
@import "bulma/sass/components/navbar.sass";
@import "bulma/sass/layout/hero.sass";
@import "bulma/sass/layout/section.sass";
@import "bulma/bulma.sass";
@import "custom.scss"