header {
  background: #fff;
  border-bottom: 1px solid #eee;
  padding: 20px;
  text-align: center;
  img {
    max-width:180px;
  }
}

.pagination {
  .pagination-link, .pagination-next, .pagination-previous {
    cursor: pointer;
  }
}
.logout {
  cursor: pointer;
  position: absolute;
  top:20px;
  right: 20px;
  &:hover strong {
    color: $primary;
  }
}